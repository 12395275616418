<template>
  <div class="auth-wrapper auth-v2 px-2">
      <showAt breakpoint="small">
        <div class="btn_prev_container">
            <div class="btn_prev_stage_mobile" style="margin-top: 20px;"> 
                <b-link @click="goBack">
                    <feather-icon icon="ChevronUpIcon" size="24" style="color: #9CA2B1; transform: rotate(-90deg);" />
                </b-link>
            </div>
        </div>
      </showAt>
    <div class="auth-inner py-2">
    <b-overlay
          :show="is_loading"
          spinner-variant="primary"
          variant="transparent"
          rounded="sm"
    >
      <!-- Login v2 -->
      
      <b-card v-if="is_twofactor_required" class="mb-0" style="border-radius: 24px;">
            
            <center>
              <showAt breakpoint="mediumAndAbove">
                <div class="btn_prev_stage_desktop" style="margin-top: 20px;"> 
                    <b-link @click="goBack">
                        <feather-icon icon="ChevronUpIcon" size="24" style="color: #9CA2B1; transform: rotate(-90deg);" />
                    </b-link>
                </div>
              </showAt>
              <b-img v-if="skin.value === 'light' " src="~@/assets/images/logo/logo_title_flat.png" fluid width="110px" />
              <b-img v-else src="~@/assets/images/logo/logo_title_flat_white.png" fluid width="110px" />
            </center>

            <b-card-title class="text-center" style="margin-top: 32px; margin-bottom: 6px;">
              <!-- Двухфакторная аутентификация -->
              {{$t('auth.two-auth')}}
            </b-card-title>
            <b-card-text class="mb-2 text-center text-muted" style="font-size: 12px;">
              <!-- Мы отправили 6-ти значный код подтверждения на {{ userEmail }}. -->
              {{$t('auth.two-msg')}}{{ userEmail }}.
            </b-card-text>
         
          
            <validation-observer
                ref="twoFactor"
                #default="{invalid}"
            >
                
                <b-form
                    class="two-factor-form mt-2"
                    @submit.prevent="completeTwoFactor"
                >
                    <b-form-group>
                        
                        <validation-provider
                            #default="{ errors }"
                            name="«Код подтвеждения»"
                            rules="required|digits:6"
                          >
                            <b-form-input
                              id="auth-code"
                              v-model.number="c"
                              name="auth-code"
                              :state="errors.length > 0 ? false:null"
                              :placeholder="$t('auth.placeholders.code')"
                              autofocus
                            />
                            <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                        </validation-provider>
                        
                    </b-form-group>
                    
                    <b-button
                        variant="primary"
                        type="submit"
                        block
                        :disabled="invalid"
                        class="submit-auth-v2"
                    >
                        {{$t('auth.btn')}}
                    </b-button>
                    
                </b-form>
                
                <b-card-text v-if="error" variant="danger" class="text-danger text-center mt-2"> {{ error }}</b-card-text>

                <b-card-text class="text-center mt-2">
                    <span>{{$t('auth.wrong-email')}}</span> <br>
                  <b-link @click="is_twofactor_required = false">
                    <span>{{$t('auth.specify-correct')}}</span>
                  </b-link>
                </b-card-text>
                
            </validation-observer>
          
      </b-card>
      
      <b-card v-else class="mb-0" style="border-radius: 24px;">
        
        <center>
          <showAt breakpoint="mediumAndAbove">
            <div class="btn_prev_stage_desktop" style="margin-top: 20px;"> 
                <b-link @click="goBack">
                    <feather-icon icon="ChevronUpIcon" size="24" style="color: #9CA2B1; transform: rotate(-90deg);" />
                </b-link>
            </div>
          </showAt>
          <b-img v-if="skin.value === 'light'" src="~@/assets/images/logo/logo_title_flat.png" fluid width="110px" />
          <b-img v-else src="~@/assets/images/logo/logo_title_flat_white.png" fluid width="110px" />
        </center>

        <b-card-title class="text-center" style="margin-top: 32px; margin-bottom: 4px; font-size: 20px">
          {{$t('auth.title')}}
          <b-img
              src="~@/assets/images/icons/emoji/hand.png"
              fluid
              alt="hand"
              style="width: 18px; height: 18px; margin-right: 3px; margin-left: 2px"
          ></b-img>
        </b-card-title>
        <b-card-text class="mb-2 text-center text-muted" style="font-size: 14px;">
          {{$t('auth.subtitle')}}
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="validationForm"
          >

            <!-- email -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="«Электронная почта»"
                rules="required|email"
                localize="ru"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  :placeholder="$t('auth.placeholders.email')"
                  autofocus
                />
                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-end">
                <b-link :to="{ name:'auth-password-forgot' }">
                  <span style="font-size: 14px">{{$t('auth.fogot')}}</span>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="«Пароль»"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    :placeholder="$t('auth.placeholders.password')"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
              </validation-provider>
                                
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="keepMeLoginIn"
                name="checkbox-1"
              >
                {{$t('auth.remember-me')}}
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
              class="submit-auth-v2"
            >
              {{$t('auth.btn')}}
            </b-button>
          </b-form>
        </validation-observer>
        
        <b-card-text v-if="error" variant="danger" class="text-danger text-center mt-2"> {{ error }}</b-card-text>

        <b-card-text class="text-center mt-2">
          <span>{{$t('auth.no-account-yet')}}</span>
          <b-link :to="{name:'auth-register'}">
            <span>{{$t('auth.btn-register')}}</span>
          </b-link>
        </b-card-text>

      </b-card>
    </b-overlay>
      <!-- /Login v2 -->
    </div>
  </div>
</template>

<script>
    
import Charts from "@/modules/charts"    
import store from '@/store'

import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BButton, BOverlay, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email, digits, length } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

import useAppConfig from '@core/app-config/useAppConfig'

import Tickets from "@/modules/tickets/"

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BOverlay,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  metaInfo() {
    const title = this.$t('title-pages.auth');
    return {
      title: title,
      titleTemplate: null,
    }
  },
  data() {
    return {
        
      userEmail: '',
      password: '',
      status: '',
      
      keepMeLoginIn: false,
      
      error: null,
      
      is_loading: false,
      
      is_twofactor_required: false,
      i: null,
      
      skin: useAppConfig().skin,
            
      required,
      email,
      digits,
      length
    }
  },
  
  methods: {
      
    completeUserAuth(result) {
      
        if( this.keepMeLoginIn ) {
           localStorage.setItem( "access_token", result.authorization_token );
        } else {
           sessionStorage.setItem("access_token", result.authorization_token );
        }

        Tickets.subjects.get();
        Charts.get();

        store.commit( 'appConfig/UPDATE_NAV_MENU_HIDDEN', false )

        this.$user.get().then(result => {
            this.is_loading = false;
            let afterAuth = sessionStorage.getItem("after:auth");
            
            if( afterAuth ) {
                
                afterAuth = JSON.parse( afterAuth );
                
                if( afterAuth.type === 'rent' ) {
                    this.$router.replace({
                        name: "user-contracts-create",
                        query: afterAuth.data
                    });
                }
                
                else if( afterAuth.type === 'buy' ) {
                    this.$router.replace({
                        name: "user-miners-checkout"
                    });
                }

                else if( afterAuth.type === 'offer' ) {
                    this.$router.replace({
                        name: "user-contracts-create",
                        query: afterAuth.data
                    });
                }

                else if( afterAuth.type === 'offer-buy' ) {
                    this.$router.replace({
                        name: "user-miners-checkout",
                        query: afterAuth.data
                    });
                }

                else {
                    this.$router.replace({
                        name: "user-dashboard"
                    });
                }
                
            } else {
                this.$router.replace({
                  name: "user-dashboard"
                });
            } 
            
        });
        
    },
      
      
    completeTwoFactor() {
        
        this.error = null; 
        
        this.$refs.twoFactor.validate().then(success => {
            if( success ) {
                this.is_loading = true;
                this.$request.post('user.twofactor', {
                    i: this.i,
                    c: this.c
                }).then( result => {
                    this.completeUserAuth( result );
                }).catch(err => {
                   this.is_loading = false;
                   this.error = err.message; 
                });
            }
        });
                    
        
    },  
      
    validationForm() {
      
        this.error = null;  
     
        this.$refs.loginForm.validate().then(success => {
             if(!success) return;
            
             this.is_loading = true;
            
             this.$request.post("user.authorization", {
                 email: this.userEmail,
                 password: this.password
             }).then(result => {

                this.completeUserAuth( result );

             }).catch(err => {
                 if( err.is_twofactor_required ) {
                     this.is_twofactor_required = true;
                     this.i = err.i;
                 } else {
                     this.error = err.message;
                 }
                 
                 this.is_loading = false;
                 
             });
            
        });
        
    },

    goBack() {
      this.$router.push('/');
    }
      
  },
  
  beforeCreate() {
           
  },
  
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },

  mounted() {
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';


</style>
